<template>
    
</template>


<script>
export default{
    name: 'Bill',

}
</script>